import * as React from "react"

import homePageNarragansettCrowd from '../images/home-page/home-page-narragansett-crowd.webp'

export default function HomePageFeaturedImage() {
  return (
    <div className="bg-blue-bgLight text-white">
      <div className="max-w-7xl mx-auto pt-10 pb-10 px-4 xl:px-0">
        <h2 className="text-6xl font-extrabold tracking-tight sm:text-6xl">Stand With John</h2>
        <h3 className="mt-5 text-4xl font-semibold tracking-tight sm:text-4xl">Send John back to the City Council!</h3>
        <img className="mt-5" src={homePageNarragansettCrowd} />
      </div>
    </div>
  )
}
