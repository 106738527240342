import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HomePageHero from "../components/home-page-hero"
import HomePageBio from "../components/home-page-bio"
import HomePageIssues from "../components/home-page-issues"
import HomePageFeaturedImage from "../components/home-page-featured-image"
import HomePageVideo from "../components/home-page-video"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <HomePageHero />
    <HomePageBio />
    <HomePageIssues />
    <HomePageFeaturedImage />
    <HomePageVideo />
  </Layout>
)

export default IndexPage
