/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import * as React from "react"

export default function HomePageIssues() {
  return (
    <div className="relative bg-blue-bgDark text-white">
      <div className="mx-auto max-w-7xl w-full pb-8 grid lg:grid-cols-2">
        <div className="pt-8 pb-4 px-4 xl:px-0 xl:pr-16">
            <h2 className="text-6xl font-extrabold tracking-tight sm:text-6xl">Issues</h2>
            <h3 className="pt-5 text-4xl font-semibold tracking-tight sm:text-4xl">John is rooted in community. John fights for all of us.</h3>
            <a
              href="/issues"
              className="mt-10 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-2xl font-medium font-bold text-white bg-blue-buttonLight hover:bg-blue-700"
            >
              See all of John's plans for Ward 1
            </a>
        </div>
        <div className="lg:pl-0 pt-8 px-4 xl:px-0">
          <ul>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/improve-quality-of-life">&raquo; Improve Quality of Life</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/education-equity">&raquo; Education Equity</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/climate-environmental-justice">&raquo; Climate &amp; Environmental Justice</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/public-health">&raquo; Public Health</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/public-safety">&raquo; Public Safety</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/planning-development-housing-housing-affordability">&raquo; Planning &amp; Development: Housing &amp; Housing Affordability</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/transportation">&raquo; Transportation</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/small-business">&raquo; Small Business</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/economic-justice-workers-rights">&raquo; Economic Justice &amp; Workers' Rights</a></li>
          </ul>
        </div>
      </div>   
    </div>
  )
}
