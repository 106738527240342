/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import * as React from "react"
import homePageBio1 from '../images/home-page/home-page-bio-1.webp'
import homePageBio2 from '../images/home-page/home-page-bio-2.webp'
import homePageBio3 from '../images/home-page/home-page-bio-3.webp'
import homePageBio4 from '../images/home-page/home-page-bio-4-cropped-4-3-aspect-ratio.webp'
import homePageBio5 from '../images/home-page/home-page-bio-5.webp'
import homePageBio6 from '../images/home-page/home-page-bio-6.webp'

const people = [
  {
    imageUrl: homePageBio1,
  },
  {
    imageUrl: homePageBio2,
  },
  {
    imageUrl: homePageBio3,
  },
  {
    imageUrl: homePageBio4,
  },
  {
    imageUrl: homePageBio5,
  },
  {
    imageUrl: homePageBio6,
  },
]

export default function HomePageBio() {
  return (
    <div className="relative bg-blue-bgLight text-white">
      <div className="mx-auto max-w-7xl w-full pb-8 grid lg:grid-cols-2">
        <div className="pt-8 pb-4 px-4 xl:px-0 xl:pr-16">
            <h2 className="text-6xl font-extrabold tracking-tight sm:text-6xl">Meet John</h2>
            <h3 className="pt-5 text-4xl font-semibold tracking-tight sm:text-4xl">Educator and Community Leader.<br />Lifelong Ward 1 resident.<br />Born and raised in our community.</h3>
            <p className="pt-5 text-2xl">
              Councilman John Goncalves currently represents the residents of Ward One, which includes the neighborhoods of Fox Point, Wayland Square, College Hill, The Jewelry District, and Downtown Providence. He currently serves as Chairman of the Providence City Council's Committee on State Legislative Affairs, Chairman of the Committee on City Property, and Chairman of the Committee on Claims and Pending Suits.
            </p>
            <p className="pt-5 text-2xl">
              John has been a resident of Providence as a Fox Point and East Side community member for over 25 years. John is a passionate educator and DEI Coordinator at the Wheeler School in Providence and a community advocate who has worked to advance issues on education, environmental justice, and neighborhood quality of life.
            </p>
            <a
              href="/meet-john"
              className="mt-10 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-2xl font-bold text-white bg-blue-buttonMed hover:bg-blue-700"
            >
              Learn more about John
            </a>
        </div>
        <div className="lg:pl-0 pt-8 px-4 xl:px-0">
          <ul
              role="list"
              className="space-y-8 sm:space-y-0 sm:grid sm:gap-x-8 sm:gap-y-8 lg:grid-cols-2" 
          >
              {people.map((person) => (
              <li key={person.name}>
                  <div className="aspect-w-4 aspect-h-3">
                      <img className="object-cover" src={person.imageUrl} alt="" />
                  </div>
              </li>
              ))}
          </ul>
        </div>
      </div>   
    </div>
  )
}
