import * as React from "react"

export default function HomePageVideo() {
  return (
    <div className="bg-blue-bgDark">
      <div className="max-w-7xl mx-auto pt-10 pb-10 px-4 xl:px-0">
        <div class="aspect-w-16 aspect-h-9">
          <iframe src="https://www.youtube.com/embed/oB-RAysEL4w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  )
}
